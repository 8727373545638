<template>
  <validation-observer ref="stripeForm" tag="form" @submit.prevent="submit">
    <v-card>
      <v-card-title>
        Add a SEPA Direct Debit Account
        <v-spacer />
        <v-btn icon @click="$emit('done')">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-alert>
          By providing your IBAN, you are authorizing *Rocketship Inc* and Stripe, our payment service provider, to send instructions to your bank to debit your account in accordance with those instructions. Subsequent payments are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within eight weeks starting from the date on which your account was debited.
        </v-alert>
        <v-card-text v-if="error">
          <!-- Used to display form errors. -->
          <v-alert
            type="error"
            dense
            outlined
            border="left"
          >
            {{ error }}
          </v-alert>
        </v-card-text>
        <v-card-text class="justify-center">
          <div
            id="iban-element"
            class="py-3 px-1 mt-2 v-card v-card--outlined theme--light"
            style="border-color: rgba(0, 0, 0, 0.38); max-width: 500px;"
          />
        </v-card-text>
        <v-card-text class="justify-end">
          <v-checkbox v-model="makeDefault" label="Make this the default payment method." />
        </v-card-text>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          type="submit"
          :loading="loading"
          :disabled="loading"
          color="primary"
          outlined
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AddSepa',
  data: () => ({
    setupIntent: null,
    ibanElement: null,
    makeDefault: true,
    loading: false,
    error: ''
  }),
  computed: {
    ...mapGetters(['user', 'organization'])
  },
  async beforeMount() {
    const { data } = await this.$axios.get(`${this.$apiUrl}/stripe/sepa-intent`)
    this.setupIntent = data
  },
  mounted() {
    const elements = this.$stripe.elements()
    const style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    }
    const options = {
      style,
      supportedCountries: ['SEPA'],
      // If you know the country of the customer, you can optionally pass it to
      // the Element as placeholderCountry. The example IBAN that is used
      // as placeholder reflects the IBAN format of that country.
      placeholderCountry: 'DE'
    }
    this.ibanElement = elements.create('iban', options)
    this.ibanElement.mount('#iban-element')
    // Handle real-time validation errors from the card Element.
    this.ibanElement.addEventListener('change', (event) => {
      this.error = event.error ? event.error.message : ''
    })
  },
  methods: {
    // Handle form submission.
    async submit() {
      this.error = ''
      this.loading = true
      const { paymentMethod, error } = await this.$stripe.createPaymentMethod({
        type: 'card',
        card: this.cardElement,
        billing_details: {
          email: this.user.email
        }
      })
      if (error) {
        this.error = error.message
        this.loading = false
        return
      }
      const { stripeCustomerId } = this.organization
      const form = {
        // email: paymentMethod.billing_details.email,
        payment_method: paymentMethod.id,
        stripeCustomerId,
        makeDefault: this.makeDefault
      }
      try {
        const { data } = await this.$axios.post(`${this.$apiUrl}/stripe/add-payment-method`, form)
        console.log('data', data)
        console.log('success')
        this.loading = false
        this.$emit('done')
      } catch (error) {
        console.log('error', error)
        this.error = error
        this.loading = false
      }
    }
  }
}
</script>
